//langCode对应accept
const accept_map = {
  'zh_TW': 'zh-TW',
  'zh_CN': 'zh-CN',
  'en': 'en-US',
  'pt': 'pt-BR',
  'es': 'es-ES'
}


/**
 * 获取langCode
 * @param {strubg} e //浏览器语言
 * @returns 密文
 */
export function getLangCode(e) {
  let str
  switch (e) {
    case 'zh-CN':
      str = 'zh_CN'
      break
    case 'zh-TW':
      str = 'zh_CN'
      break
    case 'zh-HK':
      str = 'zh_CN'
      break
    case 'zh':
      str = 'zh_CN'
      break
    default:
      str = 'en'
      break
  }
  return str
}
/**
 * 获取accept
 * @param {strubg} langCode //浏览器语言对应的langcode
 * @returns 密文
 */
//获取langCode对应的accept
export function getLangAccept(langCode) {
  return accept_map[langCode] || 'en-US'
}
//获取本项目的langlist
export function getLangList() {
  return [
    {
      label: '简体中文',
      langCode: 'zh_CN',
      accept: 'zh-CN'
    },
    // {
    //   label: '繁体中文',
    //   langCode: 'zh_TW',
    //   accept: 'zh-TW'
    // },
    {
      label: 'English(US)',
      langCode: 'en',
      accept: 'en-US'
    },
    // {
    //   label: 'Español',
    //   langCode: 'es',
    //   accept: 'es-ES'
    // },
    {
      label: 'Português',
      langCode: 'pt',
      accept: 'pt-BR'
    }
  ] 
  
}
