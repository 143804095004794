var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.computedTitle,
        visible: _vm.visible,
        width: _vm.width,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        center: _vm.center,
        showClose: _vm.showClose,
        "append-to-body": _vm.appendToBody,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.cancel,
      },
    },
    [
      _vm._t("default"),
      _vm.hasFooter
        ? _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.cancelFlag
                ? _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.cancelTitle || _vm.$strings.cancel)),
                  ])
                : _vm._e(),
              _vm.submitFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit($event)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.submitTitle || _vm.$strings.save))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }