<template>
  <div>
    
    <el-popover popper-class="top_tag" placement="bottom" trigger="click">
      
      <div slot="reference" class="circle_32  li_h_32 m_r_16">
        <tms-icon type="zhanghao font_3 font_s_32" />
        <!-- <img src="https://t9.baidu.com/it/u=3511377157,1242388902&fm=218&app=126&size=f242,150&n=0&f=JPEG&fmt=auto?s=7C089E56B4658D1FFE93E5AD03006049&sec=1680195600&t=0b202207d2e2fe352e4825f4b27c6834"> -->
      </div>
    
      <div class="avactor_content">
        <!-- <p>{{ $strings.person_center }}</p> -->
        <p @click="changePwd">{{ $strings.change_pwd }}</p>
        <p @click="logout">{{ $strings.logout }}</p>
      </div>
    </el-popover>
  </div>
  
  
</template>
  
<script>

let timeout
export default {
  name: "Demo",
  props: {
    changePwd: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      list: [
        { name: 1, age: 2, sex: 0 }
      ],
      columns: [
        {
          prop: "name",
          label: "姓名",
          age: 0,
          width: 200
        },
        {
          label: "性别",
          prop: "age"
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {
    // window.changePwdConfirm = this.changePwdConfirm
  },
  mounted() {
 
    
  },
  beforeDestroy() {
    timeout && clearTimeout(timeout)
  },
  methods: {
    cancel() {
      this.visible = false
    },
    logout() {
      this.$tms_confirm(this.$strings.lgnout_sure, this.$strings.prompt, {
        confirmButtonText: this.$strings.submit,
        cancelButtonText: this.$strings.cancel,
        type: "warning"
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({ path: "/login" })
        })
      }).catch(error => {})
    }
    
  }
}
</script>
<style lang="scss">
@import '@/styles/theme.scss';
  .avactor_content{
    width: 124px;
    margin: 0 !important;
    cursor: pointer;
    p{
      width: 100%;
      height: 36px;
      line-height: 36px;
      padding: 7px 12px 7px 12px;
      font-size: 14px;
      @extend .font_1;
      margin: 0 !important;
    }
    p:hover{
      background: #e8efff;
      color: #165DFF;
      
    }
  }
</style>
